import React, { useState, useRef, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';
import { BlockUI } from 'primereact/blockui';

import PFFormikError from '../../shared/PFPrime/PFFormikError';
import { useAlerts } from '../../shared/Alerts/alertsService';
import PFInputText from '../../shared/PFPrime/PFInputText';
import PFInputNumber from '../../shared/PFPrime/PFInputNumber';
import PFButton from '../../shared/PFPrime/PFButton';
import PFInputSwitch from '../../shared/PFPrime/PFInputSwitch';
import { alphanumericRegex } from '../../../constants.js';

import { createLaborMasterItem, updateLaborItem } from './Items.service';

const AddEditLaborItemDialog = ({
  visible,
  setIsAddLaborItemDialogVisible,
  itemId,
  itemsReload,
  setLaborLoading,
  action,
  rowData,
  showAlert,
}) => {
  const toast = useRef(null);

  const { setAlert, clearAlert } = useAlerts();
  const [blocked, setBlocked] = useState(false);

  useEffect(() => {
    clearAlert();
  }, []);

  const handleClose = () => {
    setIsAddLaborItemDialogVisible(false);
  };
  const laborInitialValues = {
    item: (action === 'edit' && rowData?.item_number) || '',
    description: (action === 'edit' && rowData?.item_desc) || '',
    revenue_price:
      (action === 'edit' && rowData?.revenue_price !== 'N/A'
        ? rowData?.revenue_price
        : '0') || 0,
    retail_price:
      (action === 'edit' && rowData?.retail_price !== 'N/A'
        ? rowData?.retail_price
        : '0') || 0,
    inventory_managment:
      (action === 'edit' && rowData?.inventory_managment !== 'No') || false,
    exclude_from_commission:
      (action === 'edit' && rowData?.exclude_from_commission !== 'No') || false,
    exclude_from_revenue:
      (action === 'edit' && rowData?.exclude_from_revenue !== 'No') || false,
    is_sum_for_total_quantity:
      action === 'edit' && rowData?.is_sum_for_total_quantity !== 'No',
    is_basic_labor:
      (action === 'edit' && rowData?.is_basic_labor !== 'No') || false,
  };
  const LaborItemsMasterValidationSchema = Yup.object().shape({
    item: Yup.string().required('Required').min(1),
    description: Yup.string().required('Item description is required'),
    retail_price: Yup.string().trim(),
    revenue_price: Yup.string().trim(),
  });

  const laborItemMasterFormik = useFormik({
    enableReinitialize: true,
    initialValues: laborInitialValues,
    validationSchema: LaborItemsMasterValidationSchema,
    onSubmit: async values => {
      let response;
      const payload = {
        item_number: values.item.toString().trim() || '',
        item_desc: values.description.trim() || null,
        item_type_id: 2,
        inventory_managment: values?.inventory_managment || false,
        exclude_from_commission: values?.exclude_from_commission || false,
        exclude_from_revenue: values?.exclude_from_revenue || false,
        is_sum_for_total_quantity: !!values?.is_sum_for_total_quantity,
        is_basic_labor: !!values?.is_basic_labor,
      };
      if (values?.revenue_price) {
        payload.revenue_price = values?.revenue_price || 0;
      }
      if (values?.retail_price) {
        payload.retail_price = values?.retail_price || 0;
      }
      setBlocked(true);

      if (action === 'add') {
        response = await createLaborMasterItem(
          itemId,
          payload,
          setLaborLoading,
          showAlert,
          itemsReload
        );
        setLaborLoading(false);

        if (response?.data?.item_id && response?.data?.item_id > 0) {
          setBlocked(false);
          setIsAddLaborItemDialogVisible(false);
        } else {
          setBlocked(false);
        }
        resetForm();
      } else {
        const response = await updateLaborItem(
          itemId,
          rowData?.item,
          payload,
          setLaborLoading,
          setAlert,
          itemsReload
        );

        if (response?.data?.item_id && response?.data?.item_id > 0) {
          setBlocked(false);
          showAlert('success', 'Labor Item updated successfully.', 2000);
          setIsAddLaborItemDialogVisible(false);
        } else {
          showAlert('error', 'Something went wrong.', 2000);
          setBlocked(false);
        }
      }
    },
    enableReinitialize: true,
  });

  return (
    <>
      <Dialog
        baseZIndex="99"
        header={action === 'edit' ? 'Edit Labor Item' : 'Add Labor Item'}
        className="w-11 lg:w-6"
        visible={visible}
        onHide={handleClose}
        draggable={false}
        pt={{
          header: { className: 'mb-0 pb-0' },
          content: { className: 'pt-1' },
        }}
      >
        <Toast
          baseZIndex="10000"
          className="taks-toast"
          style={{ zIndex: '9999!important' }}
          ref={toast}
        />

        <BlockUI
          blocked={blocked}
          className="opacity-30"
          pt={{ mask: { className: 'gray-bg-300' } }}
        >
          <div className="card w-12">
            <form
              onSubmit={laborItemMasterFormik.handleSubmit}
              className="flex flex-column gap-2"
            >
              <div className="grid mt-2">
                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <PFInputText
                      id="item"
                      name="item"
                      value={laborItemMasterFormik?.values?.item}
                      onChange={e => {
                        laborItemMasterFormik.setFieldValue(
                          'item',
                          e?.target?.value
                        );
                      }}
                      minLength={1}
                      className={
                        laborItemMasterFormik?.touched?.item &&
                        laborItemMasterFormik?.errors?.item
                          ? 'border-red-600 w-12'
                          : ' w-12'
                      }
                    />
                    <label htmlFor="item">
                      Item # <span className="text-red-500">*</span>
                    </label>
                  </span>
                  <PFFormikError
                    touched={laborItemMasterFormik.touched}
                    errors={laborItemMasterFormik.errors}
                    field="item"
                  />
                </div>

                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <PFInputText
                      inputId="description"
                      name="description"
                      value={laborItemMasterFormik?.values?.description}
                      onChange={laborItemMasterFormik.handleChange}
                      className={
                        laborItemMasterFormik?.values?.touched?.description &&
                        laborItemMasterFormik?.values?.errors?.description
                          ? 'border-red-600 w-12'
                          : ' w-12'
                      }
                    />
                    <label htmlFor="description">
                      Item Description <span className="text-red-500">*</span>
                    </label>
                  </span>
                  <PFFormikError
                    touched={laborItemMasterFormik?.touched}
                    errors={laborItemMasterFormik?.errors}
                    field="description"
                  />
                </div>

                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <PFInputNumber
                      id="retail_price"
                      name="retailPrice"
                      value={laborItemMasterFormik?.values?.retail_price}
                      onChange={(name, value) =>
                        laborItemMasterFormik?.setFieldValue(
                          'retail_price',
                          value !== null ? value || '0.00' : 0
                        )
                      }
                      min={0}
                      minFractionDigits={2}
                      className="w-full"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                    />
                    <label htmlFor="retail_price">Retail Ea $</label>
                  </span>
                </div>

                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <PFInputNumber
                      id="revenue_price"
                      name="costPrice"
                      value={laborItemMasterFormik?.values?.revenue_price}
                      onChange={(name, value) =>
                        laborItemMasterFormik?.setFieldValue(
                          'revenue_price',
                          value !== null ? value || '0.00' : 0
                        )
                      }
                      min={0}
                      minFractionDigits={2}
                      className="w-full"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                    />
                    <label htmlFor="revenue_price">Revenue Ea $</label>
                  </span>
                </div>

                <div className="col-12 md:col-6 flex">
                  <div className="col-12 p-0 mt-2">
                    <span className="p-float-label">
                      <PFInputSwitch
                        name="exclude_from_revenue"
                        onChange={e =>
                          laborItemMasterFormik.setFieldValue(
                            'inventory_managment',
                            e.value
                          )
                        }
                        checked={
                          laborItemMasterFormik?.values?.inventory_managment
                            ? true
                            : false
                        }
                        className="align-items-center"
                      />
                      <label className="text-600 text-sm ml-6">
                        Enable Inventory
                      </label>
                    </span>
                  </div>
                </div>

                <div className="col-12 md:col-6 flex">
                  <div className="col-12 p-0 mt-2">
                    <span className="p-float-label">
                      <PFInputSwitch
                        name="pf-Inclusive"
                        onChange={e =>
                          laborItemMasterFormik.setFieldValue(
                            'exclude_from_commission',
                            e.value
                          )
                        }
                        checked={
                          laborItemMasterFormik?.values?.exclude_from_commission
                            ? true
                            : false
                        }
                        className="align-items-center"
                      />
                      <label className="text-600 text-sm ml-6">
                        Exclude from Commission
                      </label>
                    </span>
                  </div>
                </div>

                <div className="col-12 md:col-6 flex">
                  <div className="col-12 p-0 mt-2">
                    <span className="p-float-label">
                      <PFInputSwitch
                        name="pf-Inclusive"
                        onChange={e => {
                          laborItemMasterFormik.setFieldValue(
                            'exclude_from_revenue',
                            e.value
                          );
                        }}
                        checked={
                          laborItemMasterFormik?.values?.exclude_from_revenue
                            ? true
                            : false
                        }
                        className="align-items-center"
                      />
                      <label className="text-600 text-sm ml-6">
                        Exclude from Revenue
                      </label>
                    </span>
                  </div>
                </div>

                <div className="col-12 md:col-6 flex">
                  <div className="col-12 p-0 mt-2">
                    <span className="p-float-label">
                      <PFInputSwitch
                        name="pf-Inclusive"
                        onChange={e => {
                          laborItemMasterFormik.setFieldValue(
                            'is_sum_for_total_quantity',
                            e.value
                          );
                        }}
                        checked={
                          laborItemMasterFormik?.values
                            ?.is_sum_for_total_quantity
                            ? true
                            : false
                        }
                        className="align-items-center"
                      />
                      <label className="text-600 text-sm ml-6">
                        Sum for Total Quantity
                      </label>
                    </span>
                  </div>
                </div>

                <div className="col-12 md:col-6 flex">
                  <div className="col-12 p-0 mt-2">
                    <span className="p-float-label">
                      <PFInputSwitch
                        name="is_basic_labor"
                        onChange={e => {
                          laborItemMasterFormik.setFieldValue(
                            'is_basic_labor',
                            e.value
                          );
                        }}
                        checked={
                          laborItemMasterFormik?.values?.is_basic_labor
                            ? true
                            : false
                        }
                        className="align-items-center"
                      />
                      <label className="text-600 text-sm ml-6">
                        Basic Labor
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 md:col-12 px-0 text-right">
                {!blocked && (
                  <PFButton
                    type="button"
                    label="Cancel"
                    className="ml-2"
                    size="small"
                    severity="primary"
                    outlined
                    onClick={handleClose}
                  />
                )}

                <PFButton
                  label={blocked ? 'Saving' : 'Save'}
                  icon={blocked && 'pi pi-spin pi-spinner'}
                  className="ml-2"
                  size="small"
                  disabled={!laborItemMasterFormik.dirty || blocked}
                  type="submit"
                />
              </div>
            </form>
          </div>
        </BlockUI>
      </Dialog>
    </>
  );
};

export default AddEditLaborItemDialog;
