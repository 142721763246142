import React, { useState, useRef, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';
import { FileUpload } from 'primereact/fileupload';
import { BlockUI } from 'primereact/blockui';

import PFFormikError from '../../shared/PFPrime/PFFormikError';
import { Alert } from '../../shared/Alerts/Alert';
import { useAlerts } from '../../shared/Alerts/alertsService';
import PFInputNumber from '../../shared/PFPrime/PFInputNumber';
import PFInputText from '../../shared/PFPrime/PFInputText';
import PFButton from '../../shared/PFPrime/PFButton';
import {
  alphanumericRegex,
  CONFIG,
  ImageFormatType,
} from '../../../constants.js';
import PFInputSwitch from '../../shared/PFPrime/PFInputSwitch';
import PFAutoComplete from '../../shared/PFPrime/PFAutoComplete.jsx';

import { useStyles } from './Items.styles.js';
import {
  addNewManufacturerVendor,
  createMerchandiseMasterItem,
  getManufacturerOptions,
  getManufacturerVendorOptions,
  getVendorOptions,
  updateMerchandiseItem,
} from './Items.service';

const AddEditMerchandiseItemDialog = ({
  visible,
  setIsAddMerchandiseItemDialogVisible,
  itemId,
  itemsReload,
  setMerchandiseLoading,
  action,
  rowData,
  showAlert,
}) => {
  const toast = useRef(null);
  const fileUploadRef = useRef();

  const classes = useStyles();

  const [binaryImage, setBinaryImage] = useState();
  const { alert, setAlert, clearAlert } = useAlerts();

  const [imgErrors, setImgErrors] = useState(null);
  const [imgUrlErrors, setImgUrlErrors] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [manufacturers, setManufacturers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const manufacturerRef = useRef(null);
  const vendorRef = useRef(null);

  useEffect(() => {
    clearAlert();
  }, []);

  const handleClose = () => {
    setIsAddMerchandiseItemDialogVisible(false);
  };

  const laborInitialValues = {
    item: (action === 'edit' && rowData?.item_number) || '',
    description: (action === 'edit' && rowData?.item_desc) || '',
    revenue_price:
      (action === 'edit' && rowData?.revenue_price !== 'N/A'
        ? rowData?.revenue_price
        : '0') || 0,
    cost_price:
      (action === 'edit' && rowData?.cost_price !== 'N/A'
        ? rowData?.cost_price
        : '0') || 0,
    retail_price:
      (action === 'edit' && rowData?.retail_price !== 'N/A'
        ? rowData?.retail_price
        : '0') || 0,
    inventory_managment:
      (action === 'edit' && rowData?.inventory_managment !== 'No') || false,
    express_ship:
      (action === 'edit' && rowData?.express_ship !== 'No') || false,
    image_url: (action === 'edit' && rowData?.image_url_value) || '',
    manufacturer: action === 'edit' && rowData?.manufacturer,
    vendor: action === 'edit' && rowData?.vendor,
    exclude_from_commission:
      (action === 'edit' && rowData?.exclude_from_commission !== 'No') || false,
  };

  const MerchandiseItemsMasterValidationSchema = Yup.object().shape({
    item: Yup.string().required('Required').min(1),
    description: Yup.string().required('Item description is required'),
    retail_price: Yup.string().trim(),
    cost_price: Yup.string().trim(),
    revenue_price: Yup.string().trim(),
  });

  const merchandiseItemMasterFormik = useFormik({
    enableReinitialize: true,
    initialValues: laborInitialValues,
    validationSchema: MerchandiseItemsMasterValidationSchema,
    onSubmit: async values => {
      let response;
      const payload = {
        item_number: values.item.toString().trim() || '',
        item_desc: values.description.trim() || null,
        cost_price: values?.cost_price || 0,
        retail_price: values?.retail_price || 0,
        revenue_price: values?.revenue_price || 0,
        item_type_id: 6,
        inventory_managment: values?.inventory_managment || false,
        express_ship: values?.express_ship || false,
        ...(binaryImage ? { file: binaryImage } : {}),
        image_type: `${values.image_url?.length ? 'url' : 'upload'}`,
        exclude_from_commission: values?.exclude_from_commission || false,
      };
      if (values?.manufacturer?.id) {
        payload.manufacturer = values.manufacturer.id;
      }
      if (values?.vendor?.id) {
        payload.vendor = values.vendor.id;
      }
      setBlocked(true);

      if (action === 'add') {
        response = await createMerchandiseMasterItem(
          itemId,
          payload,
          setMerchandiseLoading,
          setAlert,
          itemsReload
        );
        if (response?.data?.item_id && response?.data?.item_id > 0) {
          setBlocked(false);
          showAlert('success', 'Product item created successfully.', 1500);
          setIsAddMerchandiseItemDialogVisible(false);
        } else {
          showAlert(
            'error',
            response?.response?.data?.error || 'Something went wrong.',
            1500
          );
          setBlocked(false);
        }
        setMerchandiseLoading(false);
        resetForm();
      } else {
        response = await updateMerchandiseItem(
          rowData?.item,
          payload,
          setMerchandiseLoading,
          setAlert,
          itemsReload
        );
        if (response?.data?.item_id && response?.data?.item_id > 0) {
          setBlocked(false);
          showAlert('success', 'Product Item updated successfully.', 1500);
          setIsAddMerchandiseItemDialogVisible(false);
        } else {
          showAlert(
            'error',
            response?.response?.data?.error || 'Something went wrong.',
            1500
          );
          setBlocked(false);
        }
      }
    },
    enableReinitialize: true,
  });

  //Helper to fetch manufacturer/Vendor
  const suggestManufacturerVendor = async (type, e) => {
    let func, setValue;
    if ('manufacturer' === type) {
      func = getManufacturerOptions;
      setValue = setManufacturers;
    } else if ('vendor' === type) {
      func = getVendorOptions;
      setValue = setVendors;
    }
    if (func) {
      const searchResponse = await func(e?.query || '');
      if (Array.isArray(searchResponse)) setValue(searchResponse);
      else setValue([]);
    }
  };

  // Helper to add manufacturer/Vendor
  const addManufacturerVendor = async (type, value) => {
    if (value) {
      const data = {
        type,
        value,
      };
      const manufacturerVendorResponse = await addNewManufacturerVendor(data);
      if (manufacturerVendorResponse?.id) {
        merchandiseItemMasterFormik.setFieldValue(
          type,
          manufacturerVendorResponse
        );
        suggestManufacturerVendor(type);
        toast?.current?.show({
          severity: 'success',
          summary: `${type} added successfully`,
          life: 2000,
        });
      }
    }
  };

  // Helper to focus on manufacturer/Vendor values
  const displayManufacturerVendor = type => {
    suggestManufacturerVendor(type);
    if ('manufacturer' === type) {
      if (manufacturerRef.current) {
        manufacturerRef.current.show();
      }
    } else if ('vendor' === type) {
      if (vendorRef.current) {
        vendorRef.current.show();
      }
    }
  };

  // Helper to disabled the button to add values in dropdown
  const onDisabledManufacturerVendor = (searchResult, value, key) => {
    let res = true;

    if (searchResult?.length > 0 && value && typeof value === 'string') {
      if (value.trim() != '') {
        const length =
          searchResult?.filter(
            item => item[key]?.toLowerCase() === value?.toLowerCase()
          ).length || 0;
        if (length === 0) {
          res = false;
        }
      }
    }

    if (searchResult?.length === 0) {
      res = false;
    }
    if (!value) {
      res = true;
    }

    return res;
  };

  useEffect(() => {
    getManufacturerVendorOptions(setManufacturers, setVendors)
      .then(() => {
        setBlocked(false);
      })
      .finally(() => setBlocked(false));
  }, []);

  const onImageUpload = e => {
    if (e.files?.length <= 0) {
      showAlert('error', 'No Image Selected');
      merchandiseItemMasterFormik.setFieldValue('image_url', '');
    }
    const file = e.files[0];
    const isImageValid = ImageValidation(
      file?.type ? file.type.toLowerCase() : ''
    );
    if (isImageValid) {
      merchandiseItemMasterFormik.setFieldValue('image_url', file);
    }
  };

  const ImageValidation = type => {
    if (type?.split('/')[0] !== 'image') {
      setImgErrors('Only image files are allowed');
      showAlert('error', 'Only image files are allowed');
      return false;
    } else if (type?.includes('svg')) {
      setImgErrors('System does not accept SVG files.');
      showAlert('error', 'System does not accept SVG files.');
      return false;
    } else if (!ImageFormatType.includes(type)) {
      setImgErrors(' Uploaded image file format is not supported.');
      showAlert('error', ' Uploaded image file format is not supported.');
      return false;
    } else {
      setImgErrors(null);
      return true;
    }
  };

  useEffect(() => {
    const imgUrl = merchandiseItemMasterFormik?.values?.image_url;
    if (imgUrl || imgUrl !== '') {
      const imgType = imgUrl?.split('.');

      const type = imgType[imgType?.length - 1];

      if (
        type !== 'png' &&
        type !== 'jpg' &&
        type !== 'jpeg' &&
        type !== 'gif'
      ) {
        if (type.length < 5) {
          setImgUrlErrors(true);
        }
      } else {
        setImgUrlErrors(false);
      }
    }
  }, [merchandiseItemMasterFormik.values.image_url]);

  return (
    <>
      {alert.exists && (
        <div>
          <Alert />
        </div>
      )}
      <Toast ref={toast} />
      <Dialog
        header={action === 'edit' ? 'Edit Product Item' : 'Add Product Item'}
        className="w-11 xl:w-8"
        visible={visible}
        onHide={handleClose}
        draggable={false}
        pt={{
          header: { className: 'mb-0 pb-0' },
          content: { className: 'pt-1' },
        }}
      >
        <BlockUI
          blocked={blocked}
          className="opacity-30"
          pt={{ mask: { className: 'gray-bg-300' } }}
        >
          <div className="card w-12">
            <form
              onSubmit={merchandiseItemMasterFormik.handleSubmit}
              className="flex flex-column gap-2"
            >
              <div className="grid mt-2">
                <div className="col-12 md:col-6 flex align-items-start">
                  <>
                    <div>
                      <Image
                        src={
                          merchandiseItemMasterFormik?.values?.image_url
                            ?.objectURL
                            ? merchandiseItemMasterFormik?.values?.image_url
                                ?.objectURL
                            : merchandiseItemMasterFormik?.values?.image_url
                              ? merchandiseItemMasterFormik?.values?.image_url
                              : 'https://placehold.jp/14/d1d5db/666/100x100.png?text=No Image'
                        }
                        alt="MerchandiseImage"
                        preview={
                          merchandiseItemMasterFormik?.values?.image_url
                            ?.objectURL ||
                          merchandiseItemMasterFormik?.values?.image_url
                        }
                        indicatorIcon={<i className="pi pi-search"></i>}
                        pt={{
                          image: {
                            className: 'border-round w-8rem max-h-4rem',
                            style: { objectFit: 'cover' },
                          },
                        }}
                      />
                    </div>
                    <div className="p-error">
                      {imgUrlErrors && 'Image must be JPG, PNG or GIF'}
                    </div>
                  </>

                  <div className="flex align-self-center align-items-center justify-content-center ml-3">
                    <FileUpload
                      ref={fileUploadRef}
                      mode="basic"
                      multiple
                      name="merchandiseImage"
                      customUpload
                      auto
                      className
                      file={merchandiseItemMasterFormik?.values?.image_url}
                      onSelect={e => {
                        if (
                          e?.files &&
                          e?.files[0] &&
                          ImageValidation(e?.files[0].type)
                        ) {
                          setBinaryImage(e?.files[0]);
                          merchandiseItemMasterFormik.setFieldValue(
                            'image_url',
                            URL.createObjectURL(e?.files[0])
                          );
                        }
                        fileUploadRef.current?.clear();
                      }}
                      chooseLabel={
                        merchandiseItemMasterFormik?.values?.image_url
                          ? 'Change Image'
                          : 'Upload Image'
                      }
                      chooseOptions={{
                        icon: <i className="pi pi-upload mr-2"></i>,
                        label: null,
                      }}
                      pt={{
                        basicbutton: {
                          className: 'p-button-sm p-button-primary',
                        },
                      }}
                    />
                  </div>
                </div>

                <div className="col-12 md:col-6">
                  <div className="col-12 flex  flex-column md:flex-row pl-0">
                    <div className="col-12 md:col-6  p-0 mt-2 flex align-items-center">
                      <PFInputSwitch
                        name="pf-Inclusive"
                        onChange={e =>
                          merchandiseItemMasterFormik.setFieldValue(
                            'inventory_managment',
                            e.value
                          )
                        }
                        checked={
                          merchandiseItemMasterFormik?.values
                            ?.inventory_managment
                            ? true
                            : false
                        }
                        className="align-items-center"
                      />
                      <label className="text-600 text-sm ml-3">
                        Enable Inventory
                      </label>
                    </div>
                    <div className="col-12 md:col-6 p-0 mt-2 flex align-items-center">
                      <PFInputSwitch
                        name="pf-Inclusive"
                        onChange={e =>
                          merchandiseItemMasterFormik?.setFieldValue(
                            'express_ship',
                            e.value
                          )
                        }
                        checked={
                          merchandiseItemMasterFormik?.values?.express_ship
                            ? true
                            : false
                        }
                        className="align-items-center"
                      />
                      <label className="text-600 text-sm ml-3">
                        Express Ship
                      </label>
                    </div>
                  </div>

                  <div className="col-12 p-0 mt-2 flex align-items-center">
                    <PFInputSwitch
                      name="pf-Inclusive"
                      onChange={e =>
                        merchandiseItemMasterFormik?.setFieldValue(
                          'exclude_from_commission',
                          e.value
                        )
                      }
                      checked={
                        merchandiseItemMasterFormik?.values
                          ?.exclude_from_commission
                          ? true
                          : false
                      }
                      className="align-items-center"
                    />
                    <label className="text-600 text-sm ml-3">
                      Exclude from commission
                    </label>
                  </div>
                </div>

                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <PFInputText
                      id="item"
                      name="item"
                      value={merchandiseItemMasterFormik?.values?.item}
                      onChange={e => {
                        merchandiseItemMasterFormik.setFieldValue(
                          'item',
                          e?.target?.value
                        );
                      }}
                      minLength={1}
                      className={
                        merchandiseItemMasterFormik?.touched?.item &&
                        merchandiseItemMasterFormik?.errors?.item
                          ? 'border-red-600 w-12'
                          : ' w-12'
                      }
                    />
                    <label htmlFor="item">
                      Item #<span className="text-red-500">*</span>
                    </label>
                  </span>
                  <PFFormikError
                    touched={merchandiseItemMasterFormik.touched}
                    errors={merchandiseItemMasterFormik.errors}
                    field="item"
                  />
                </div>

                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <PFInputText
                      inputId="description"
                      name="description"
                      value={merchandiseItemMasterFormik?.values?.description}
                      onChange={merchandiseItemMasterFormik.handleChange}
                      className={
                        merchandiseItemMasterFormik?.values?.touched
                          ?.description &&
                        merchandiseItemMasterFormik?.values?.errors?.description
                          ? 'border-red-600 w-12'
                          : ' w-12'
                      }
                    />
                    <label htmlFor="description">
                      Item Description <span className="text-red-500">*</span>
                    </label>
                  </span>
                  <PFFormikError
                    touched={merchandiseItemMasterFormik?.touched}
                    errors={merchandiseItemMasterFormik?.errors}
                    field="description"
                  />
                </div>

                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <PFAutoComplete
                      ref={manufacturerRef}
                      id="manufacturer"
                      className="w-10"
                      suggestions={manufacturers || []}
                      field="value"
                      completeMethod={e =>
                        suggestManufacturerVendor('manufacturer', e)
                      }
                      value={
                        merchandiseItemMasterFormik?.values?.manufacturer || ''
                      }
                      onChange={e => {
                        merchandiseItemMasterFormik.setFieldValue(
                          'manufacturer',
                          e?.target?.value || ''
                        );
                      }}
                      delay={500}
                      onFocus={() => displayManufacturerVendor('manufacturer')}
                      inputClassName="p-inputtext-sm w-12 border-right-none border-noround-right"
                    />
                    <label htmlFor="manufacturer">Manufacturer</label>
                    <PFButton
                      icon="pi pi-check"
                      className="p-button-primary w-2 border-noround-left height-40"
                      disabled={onDisabledManufacturerVendor(
                        manufacturers,
                        merchandiseItemMasterFormik?.values?.manufacturer,
                        'value'
                      )}
                      onClick={() =>
                        addManufacturerVendor(
                          'manufacturer',
                          merchandiseItemMasterFormik?.values?.manufacturer
                        )
                      }
                    />
                  </span>
                  <PFFormikError
                    touched={merchandiseItemMasterFormik?.touched}
                    errors={merchandiseItemMasterFormik?.errors}
                    field="manufacturer"
                  />
                </div>

                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <PFAutoComplete
                      ref={vendorRef}
                      id="vendor"
                      className="w-10"
                      suggestions={vendors || []}
                      field="value"
                      completeMethod={e =>
                        suggestManufacturerVendor('vendor', e)
                      }
                      value={merchandiseItemMasterFormik?.values?.vendor || ''}
                      onChange={e => {
                        merchandiseItemMasterFormik.setFieldValue(
                          'vendor',
                          e?.target?.value || ''
                        );
                      }}
                      delay={500}
                      onFocus={() => displayManufacturerVendor('vendor')}
                      inputClassName="p-inputtext-sm w-12 border-right-none border-noround-right"
                    />
                    <label htmlFor="vendor">Vendor</label>

                    <PFButton
                      icon="pi pi-check"
                      className="p-button-primary w-2 border-noround-left height-40"
                      disabled={onDisabledManufacturerVendor(
                        vendors,
                        merchandiseItemMasterFormik?.values?.vendor,
                        'value'
                      )}
                      onClick={() =>
                        addManufacturerVendor(
                          'vendor',
                          merchandiseItemMasterFormik?.values?.vendor
                        )
                      }
                    />
                  </span>
                  <PFFormikError
                    touched={merchandiseItemMasterFormik?.touched}
                    errors={merchandiseItemMasterFormik?.errors}
                    field="vendor"
                  />
                </div>

                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <PFInputNumber
                      id="retail_price"
                      name="retailPrice"
                      value={merchandiseItemMasterFormik?.values?.retail_price}
                      onChange={(name, value) =>
                        merchandiseItemMasterFormik?.setFieldValue(
                          'retail_price',
                          value !== null ? value || '0.00' : 0
                        )
                      }
                      min={0}
                      minFractionDigits={2}
                      className="w-full"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                    />
                    <label htmlFor="retail_price">Retail Ea $</label>
                  </span>
                </div>

                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <PFInputNumber
                      id="revenue_price"
                      name="revenuePrice"
                      value={merchandiseItemMasterFormik?.values?.revenue_price}
                      onChange={(name, value) =>
                        merchandiseItemMasterFormik?.setFieldValue(
                          'revenue_price',
                          value !== null ? value || '0.00' : 0
                        )
                      }
                      min={0}
                      minFractionDigits={2}
                      className="w-full"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                    />
                    <label htmlFor="revenue_price">Revenue Ea $</label>
                  </span>
                </div>

                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <PFInputNumber
                      id="cost_price"
                      name="costPrice"
                      value={merchandiseItemMasterFormik?.values?.cost_price}
                      onChange={(name, value) =>
                        merchandiseItemMasterFormik?.setFieldValue(
                          'cost_price',
                          value !== null ? value || '0.00' : 0
                        )
                      }
                      min={0}
                      minFractionDigits={2}
                      className="w-full"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                    />
                    <label htmlFor="cost_price">Cost Ea $</label>
                  </span>
                </div>

                {laborInitialValues?.image_url ? (
                  <div className="col-12 md:col-6">
                    <span className="p-float-label">
                      <PFInputText
                        inputId="image"
                        name="image"
                        value={laborInitialValues?.image_url}
                        className=" w-12"
                        disabled
                      />
                      <label htmlFor="image">Image URL</label>
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="col-12 md:col-12 flex justify-content-end">
                {!blocked && (
                  <PFButton
                    type="button"
                    label="Cancel"
                    className="ml-2"
                    size="small"
                    severity="info"
                    outlined
                    onClick={handleClose}
                  />
                )}

                <PFButton
                  label={blocked ? 'Saving' : 'Save'}
                  icon={blocked && 'pi pi-spin pi-spinner'}
                  className="ml-2"
                  size="small"
                  disabled={!merchandiseItemMasterFormik.dirty || blocked}
                  type="submit"
                />
              </div>
            </form>
          </div>
        </BlockUI>
      </Dialog>
    </>
  );
};

export default AddEditMerchandiseItemDialog;
