import apiService from '../../../services/api.service';

export const getHomeDepotData = async (projectId, setLoading) => {
  setLoading(true);
  try {
    const response = await apiService.get(
      `/system/lrrp-configuration/${projectId}`
    );
    if (response) {
      setLoading(false);

      return response;
    }
  } catch (error) {
    setLoading(false);
    return error;
  }
};

export const updateHomeDepot = async (projectId, values, setLoading) => {
  setLoading(true);
  try {
    const response = await apiService.post(
      `/system/lrrp-configuration/${projectId}`,
      {
        lswp_required: values?.lswp_required,
        lead_test_req: values?.lead_test_req,
        lswp_followed: values?.lswp_followed,
        hd_lead_test_result: values?.hd_lead_test_result,
        epa_override_reason_code_id: values?.epa_override_reason_code_id,
        epa_lead_assessment_required: values?.lswp_required,
        epa_lead_remediation: values?.lead_test_req,
      }
    );
    if (response) {
      setLoading(false);
      return response;
    }
  } catch (error) {
    setLoading(false);
    return error;
  }
};
