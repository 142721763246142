import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, IconButton, Link } from '@material-ui/core';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// **** Custom Components ****
import { useSelector } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';
import { Message } from 'primereact/message';

import GenericDataTable from '../shared/GenericDataTable/GenericDataTable';
import MapContainer from '../shared/SchedulerMap';
import { useAlerts } from '../shared/Alerts/alertsService';
import GenericDialog from '../shared/Dialog/GenericDialog';
import { PROJECT_REPORT } from '../../../src/constants';
import { Alert } from '../shared/Alerts/Alert';
import Notes from '../ProjectManagement/Notes/Notes';
import Installer from '../ProjectManagement/ScheduleInfo/Installer';
import { useStyles } from '../ProjectManagement/Scheduling/Reports/Reports.styles';
import {
  getReportResponse,
  getAssignedAndUnassignedJobsMap,
  getProjectDataById,
  updateProjectScheduleInfo,
  getRescheduleReasonOptions,
  getStoresData,
  getDefaultFilterList,
} from '../ProjectManagement/Scheduling/Reports/Reports.service';
import {
  checkPermission,
  checkUrlString,
  handleMasterSearch,
  getDefaultTechnicianSchedulerView,
  getDefaultSchedulerView,
} from '../../utils/Helpers';
import permissions from '../../config/permissions';
import TableLoader from '../shared/Loader/TableLoader';
import BlockLoader from '../shared/Loader/BlockLoader';
import PFButton from '../shared/PFPrime/PFButton';

import ProjectDetailsViewEditForm from './ProjectDetailsViewEditForm';
import { getCalendarData } from './Scheduler.service';
import NewScheduler from './new-scheduler/new-schduler';
import CalenderContainer from './CalenderContainer';
import CustomFilter from './CutomFilter/CustomFilter';
import {
  getSearchProjectWorkRoomList,
  getSearchStoreList,
  getUserTypesList,
  getCategoryList,
} from './schedulerMasterServices';
import TechnicianFilter from './TechnicianFilter';
import { processFilters } from './helpers/helpers';
import ProjectDetails from './ProjectDetails';
import SchedulerTabs from './scheduler-tabs';
import AlertInformation from './AlertMessage';

const ProjectReport = () => {
  const classes = useStyles();
  const { projectNumber } = useParams();
  const [view, setView] = useState('calendar');
  const [loading, setLoading] = useState(true);
  const [schedulerLoader, setSchedulerLoader] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [storeNumberOptions, setStoreNumberOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [installerOptions, setInstallerOptions] = useState([]);
  const [projectTypeOptions, setProjectTypeOptions] = useState([]);
  const [reloadCalender, setReloadCalender] = useState(true);
  const [reloadList, setReloadList] = useState(false);
  const { alert, setAlert } = useAlerts();
  const [calendarFilterData, setCalendarFilterData] = useState();
  const [calendarFullScreen, setCalendarFullScreen] = useState(false);
  const [projectDataLoading, setProjectDataLoading] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [rescheduleReasonOptions, setRescheduleReasonOptions] = useState();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [workRoomOptions, setWorkRoomOptions] = useState([]);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [userTypesOptions, setUserTypesOptions] = useState([]);
  const [searchStoreText, setSearchStoreText] = useState('');
  const [searchWorkroomText, setSearchWorkroomText] = useState('');
  const [filterCalenderData, setFilterCalenderData] = useState('');
  const [childGetParms, setChildGetParms] = useState(null);
  const [calendarData, setCalendarData] = useState([]);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [calendarViewAPICall, setCalendarViewAPICall] = useState(false);
  const [openScheduleModel, setOpenScheduleModel] = useState(false);
  const [showTechnicianFilter, setShowTechnicianFilter] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [applyFilter, setApplyFilter] = useState(false);
  const [checkedStores, setCheckedStores] = useState(false);
  const [checkedAssigned, setCheckedAssigned] = useState(1);
  const [checkedUnAssigned, setCheckedUnAssigned] = useState(0);
  const [stores, setStores] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [mapDate, setMapDate] = useState(
    moment(new Date()).format('MM-DD-yyyy hh:mm A')
  );
  const [filterDates, setFilterDates] = useState({
    start_date: moment(new Date()).format('MM-DD-yyyy'),
    end_date: moment(new Date()).format('MM-DD-yyyy'),
  });
  const [searchQuery, setSearchQuery] = useState({
    startDate: moment(new Date()).format('MM-DD-yyyy'),
    endDate: moment(new Date()).format('MM-DD-yyyy'),
    assigned: 1,
    unAssigned: 0,
  });
  const currDate = moment(new Date()).format('MM-DD-YYYY');

  const [RTSSearchQuery, setRTSSearchQuery] = useState({
    limit: 10,
    offset: 0,
    searchQuery: projectNumber,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [dialogSettings, setDialogSettings] = useState({
    title: 'Edit Project Info',
    button1Text: '',
    button2Text: 'Save',
    showButton1: true,
    showButton2: true,
  });
  const [storeLoader, setStoreLoader] = useState(false);
  const [userTypeLoader, setUserTypeLoader] = useState(false);
  const [workRoomLoader, setWorkRoomLoader] = useState(false);
  const [selected, setSelected] = useState(null);
  const [initialLoader, setInitialLoader] = useState(true);
  const [selectedRow, setSelectedRow] = useState();
  const currentPath = window?.location?.href;
  const isScheduler = checkUrlString(currentPath, '/scheduler');
  const [schedulerComponent, setSchedulerComponent] = useState(
    isScheduler ? false : true
  );
  const [isFiltersSelected, setIsFiltersSelected] = useState(false);
  const [isProjectScheduler, setIsProjectScheduler] = useState(false);
  const datePeriodForTechnicianView = getDefaultSchedulerView() || 'day';
  const [datePeriodForCalenderView, setDatePeriodForCalenderView] = useState(
    getDefaultTechnicianSchedulerView() || 'D'
  );
  const [selectAllTech, setSelectAllTech] = useState(false);
  const { technicians } = useSelector(state => state.technicians);
  const { types } = useSelector(state => state.types);
  const { projectStores } = useSelector(state => state.projectStores);
  const { categories } = useSelector(state => state.categories);
  const { districts } = useSelector(state => state.districts);
  const { userTypes } = useSelector(state => state.userTypes);
  const { workrooms } = useSelector(state => state.workrooms);
  const { projectStatusType } = useSelector(state => state.projectStatusType);
  const { sourceStatusType } = useSelector(state => state.sourceStatusType);
  const pathName = window?.location?.pathname;

  const setMasterScheduler = () => {
    setInstallerOptions(technicians?.slice(0, 10));
    setProjectTypeOptions(types);
    setCategoryOptions(categories?.slice(0, 10));
    setStoreOptions(projectStores);
    setDistrictOptions(districts);
    setUserTypesOptions(sourceStatusType);
    setWorkRoomOptions(workrooms);
    setStatusOptions(projectStatusType);
    setStoreNumberOptions(projectStores);
    setUserTypesOptions(userTypes);
  };
  useEffect(() => {
    setMasterScheduler();
  }, [projectStatusType]);

  const handleTechnicianSearch = searchString => {
    const returenedInstaller = handleMasterSearch(
      searchString,
      technicians,
      'full_name'
    );
    setInstallerOptions(returenedInstaller);
  };

  const handleCategorySearch = searchString => {
    const returenedCategory = handleMasterSearch(
      searchString,
      categories,
      'category'
    );
    setCategoryOptions(returenedCategory);
  };

  useEffect(() => {
    setMasterScheduler();
  }, [showTechnicianFilter]);

  const readyToScheduleTableColumns = [
    {
      name: '',
      label: '',
      options: {
        sort: false,
        filter: false,
        customBodyRenderLite: dataIndex => (
          <span>
            <IconButton classes={{ root: classes.actionIcons }}>
              <Link
                to={`/project/view/${
                  readyToScheduleDataArray &&
                  readyToScheduleDataArray[dataIndex]?.project_id
                }`}
                target="_blank"
              >
                <VisibilityIcon />
              </Link>
            </IconButton>
          </span>
        ),
      },
    },
    {
      name: 'rts_follow_up_date',
      label: 'RTS Follow-up Date',
      options: {
        filter: false,
        setCellProps: () => ({ className: 'w-10rem max-w-10rem' }),
      },
    },
    {
      name: 'date_sold',
      label: 'Date Sold',
      options: {
        filter: false,
        setCellProps: () => ({ className: 'w-7rem max-w-7rem' }),
      },
    },
    {
      name: 'customerId',
      label: 'Client Name',
      options: {
        sort: true,
        filterType: 'custom',
        setCellProps: () => ({ className: 'w-9rem max-w-9rem' }),
      },
    },
    {
      name: 'mobile_number',
      label: 'Mobile No.',
      options: {
        filter: false,
        setCellProps: () => ({ className: 'w-8rem max-w-8rem' }),
      },
    },
    {
      name: 'home_phone',
      label: 'Home Phone',
      options: {
        filter: false,
        setCellProps: () => ({ className: 'w-8rem max-w-8rem' }),
      },
    },
    {
      name: 'storeNumber',
      label: 'Store #',
      options: {
        sort: true,
        filter: true,
        filterType: 'custom',
        serverSide: true,
        setCellProps: () => ({ className: 'w-6rem max-w-6rem' }),
      },
    },
    {
      name: 'project',
      label: 'Project #',
      options: { filter: false },
    },
    {
      name: 'category',
      label: 'Category',
      options: {
        sort: true,
        filterType: 'custom',
        setCellProps: () => ({ className: 'w-10rem max-w-10rem' }),
      },
    },
    {
      name: 'market',
      label: 'Market',
      options: { filter: false },
    },
    {
      name: 'projectTypeId',
      label: 'Type',
      options: {
        sort: true,
        filterType: 'custom',
        setCellProps: () => ({ className: 'w-7rem max-w-7rem' }),
      },
    },
    {
      name: 'statusId',
      label: 'Status',
      options: {
        sort: false,
        filterType: 'custom',
        customBodyRender: (value, tableMeta, updateValue) => (
          <Typography
            variant="subtitle2"
            className={
              (value === 'Completed' && classes.statusColorCompleted) ||
              (value === 'Cancelled' && classes.statusColorCancelled) ||
              classes.statusColor
            }
          >
            {value}
          </Typography>
        ),
        setCellProps: () => ({ className: 'w-6rem max-w-6rem' }),
      },
    },
    {
      name: 'imsStatus',
      label: 'Source Status',
      options: {
        sort: false,
        filterType: 'custom',
        setCellProps: () => ({ className: 'w-6rem max-w-6rem' }),
      },
    },
    {
      name: 'installation_address',
      label: 'Installation Address',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ className: 'w-9rem max-w-9rem' }),
      },
    },
    {
      name: 'districtId',
      label: 'District',
      options: {
        sort: false,
        filterType: 'custom',
        setCellProps: () => ({ className: 'w-9rem max-w-9rem' }),
      },
    },
    {
      name: 'workroomId',
      label: 'Workroom',
      options: {
        sort: false,
        filterType: 'custom',
        setCellProps: () => ({ className: 'w-9rem max-w-9rem' }),
      },
    },
  ];

  useEffect(() => {
    const pathname = window?.location?.pathname;
    if (isScheduler && pathname && pathname.split('/')?.length > 2) {
      setIsProjectScheduler(true);
      setSchedulerComponent(true);
    }
  }, []);

  useEffect(() => {
    if (alert.scrollWindow) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [alert.scrollWindow]);

  // ******* Get Scheduler Page Report and Map Data *******
  useEffect(() => {
    if (isScheduler) {
      getReportResponse(
        6,
        undefined,
        RTSSearchQuery,
        selectedFilters,
        setLoading,
        setReportData,
        setSchedulerLoader
      );
      setSelectedRow({
        client_name: null,
        project_number: null,
        store_name: null,
        project_type: null,
        po_number: null,
        category: null,
        store_number: null,
      });
      // setCalendarFilterData({
      //   client_name: null,
      //   project_number: null,
      //   store_name: null,
      //   project_type: null,
      //   po_number: null,
      // });
    }
  }, [reloadList, RTSSearchQuery, selectedFilters]);
  // ************************************
  // ********* Map - Handlers *********
  // ************************************

  // ******* Get Map Data on Filters Changes *******

  useEffect(() => {
    if (applyFilter) return;
    if (searchQuery && (view === 'map' || view === 'split')) {
      setMapData([]);
      getAssignedAndUnassignedJobsMap({
        setMapData,
        searchQuery,
        setProjectDataLoading,
        setShowErrorMsg,
        stores,
        checkedStores,
      });
    }
  }, [searchQuery, view]);

  useEffect(() => {
    if (applyFilter) {
      setMapData([]);
      getAssignedAndUnassignedJobsMap({
        setMapData,
        searchQuery,
        setProjectDataLoading,
        setShowErrorMsg,
      });
      setApplyFilter(false);
    }
  }, [searchQuery, applyFilter]);

  useEffect(() => {
    if (checkedStores && (view === 'map' || view === 'split')) {
      getStoresData({
        setProjectDataLoading,
        mapData,
        setMapData,
        setStores,
        stores,
      });
    }
  }, [checkedStores]);

  const fetchDefaultFilterList = async () => {
    const response = await getDefaultFilterList();
    let data = [
      {
        filter_id: 'AC',
        filter_name: 'Custom',
      },
      ...(response ?? []),
    ];
    setDefaultFilters(data);
  };
  const handleDefaultFilters = value => {
    const selectedFilters = processFilters(value);
    setSelectedFilters(selectedFilters);
  };

  useEffect(async () => {
    await fetchDefaultFilterList();
    const getCategoryOptions = async () => {
      try {
        const response = await getCategoryList();
        setCategoryOptions(response);
      } catch (error) {
        console.log(error);
      }
    };
    getCategoryOptions();
  }, []);

  useEffect(() => {
    if (RTSSearchQuery?.searchQuery && reportData?.items) {
      setCalendarFilterData({
        project_type_id: reportData?.items[0]?.project_type_id,
        project_category_id: reportData?.items[0]?.project_category_id,
        project_store_id: reportData?.items[0]?.store_id,
        isJobCoverage: true,
      });
      setSelected(
        mapData.filter(
          map => map.project_id === reportData?.items[0]?.project_id
        )[0]
      );
    }
  }, [reportData, mapData]);
  const hasSetSelectedRow = useRef(false);
  useEffect(() => {
    if (reportData?.items && !hasSetSelectedRow.current) {
      if (isScheduler && pathName && pathName?.split('/')?.length > 2) {
        setSelectedRow({
          client_name: reportData?.items[0]?.client_name,
          project_number: reportData?.items[0]?.project_number,
          store_name: reportData?.items[0]?.store_name,
          project_type: reportData?.items[0]?.project_type,
          po_number: reportData?.items[0]?.po_number,
          store_number: reportData?.items[0]?.store_number,
          category: reportData?.items[0]?.category,
        });
        hasSetSelectedRow.current = true;
      }
    }
  }, [reportData, isScheduler]);

  // Toggle Between Map/Calendar/Split View
  const buttonHandler = buttonName => {
    setView(buttonName);
    setCalendarViewAPICall(true);
    setMasterScheduler();
  };

  // ******************************************
  // *** Ready To Schedule Report - Handlers ***
  // ******************************************
  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal;

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoading(true);
      setRTSSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        searchQuery: searchString || null,
      }));
    }, 500);
  };

  let readyToScheduleDataArray = useMemo(() => {
    return reportData?.items?.map(val => {
      return {
        rts_follow_up_date: val.rts_follow_up_date
          ? moment(val.rts_follow_up_date?.slice(0, 10)).format('MM-DD-YYYY')
          : null,
        date_sold: moment(val.date_sold?.slice(0, 10))?.format('MM-DD-YYYY'),
        customerId: val?.client_name,
        category: val?.category,
        market: val?.market,
        project_description: val?.category,
        project: val?.project_number,
        storeNumber: val?.store_number,
        installer: val?.Installer,
        statusId: val?.project_status,
        projectTypeId: val?.project_type,
        mobile_number: val?.mobile_phone,
        home_phone: val?.home_phone,
        imsStatus: val?.IMS_status,
        project_id: val?.project_id,
        installation_address: val?.installation_address,
        districtId: val?.district ? val?.district : null,
        workroomId: val?.workroom_label ? val?.workroom_label : null,
      };
    });
  }, [reportData]);

  const readyToScheduleDataTableOptions = {
    download: false,
    print: false,
    selectableRows: false,
    responsive: 'standard',
    tableBodyMinHeight: '200px',
    tableBodyMaxHeight: '350px',
    count: reportData?.count,
    serverSide: true,
    confirmFilters: true,
    filter: false,
    //searchText: RTSSearchQuery.searchQuery,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        'data-event': reportData?.items[rowIndex]?.project_id,
        'data-description': reportData?.items[rowIndex]?.category || '',
        onDoubleClick: () => {
          setCalendarFilterData({
            project_type_id: reportData?.items[rowIndex]?.project_type_id,
            project_category_id:
              reportData?.items[rowIndex]?.project_category_id,
            project_store_id: reportData?.items[rowIndex]?.store_id,
            isJobCoverage: true,
          });
          setSelectedRow({
            client_name: reportData?.items[rowIndex]?.client_name,
            project_number: reportData?.items[rowIndex]?.project_number,
            store_name: reportData?.items[rowIndex]?.store_name,
            project_type: reportData?.items[rowIndex]?.project_type,
            po_number: reportData?.items[rowIndex]?.po_number,
            store_number: reportData?.items[rowIndex]?.store_number,
            category: reportData?.items[rowIndex]?.category,
          });
          setSchedulerComponent(true);
          setSelected(
            mapData.filter(
              map => map.project_id === reportData?.items[rowIndex]?.project_id
            )[0]
          );
        },
      };
    },
    textLabels: {
      body: {
        noMatch:
          !loading && !readyToScheduleDataArray?.length && 'No records found',
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoading(true);
      setRTSSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setLoading(true);
      setRTSSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * RTSSearchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true);
      setRTSSearchQuery(prevState => ({
        ...prevState,
        sortKey: changedColumn,
        sortDirection: direction,
      }));
    },
    onSearchChange: searchText => {
      handleSearch(searchText);
    },
    onSearchClose: () => {
      setRTSSearchQuery(prevState => ({
        ...prevState,
        searchQuery: null,
      }));
      setCalendarFilterData({});
      setSelected(null);
    },
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],

    onFilterChange: (
      column,
      filterList,
      type,
      changedColumnIndex,
      displayData
    ) => {
      let selectedFilterOptionsKey = [];
      switch (column) {
        case 'storeNumber':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.store_number
          );
          break;
        case 'category':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.project_category_id
          );
          break;
        case 'customerId':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.customer_id
          );
          break;
        case 'imsStatus':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.status_id
          );
          break;
        case 'statusId':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.status_id
          );
          break;
        case 'projectTypeId':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.project_type_id
          );
          break;
        default:
          selectedFilterOptionsKey = [];
      }
      if (column === PROJECT_REPORT.CATEGORY) {
        column = 'projectCategoryId';
      }
      setSelectedFilters(prevState => {
        return { ...prevState, [column]: selectedFilterOptionsKey };
      });
    },
  };

  // *** Update map on Date change ***
  const changeMapDate = day => {
    const currentDate = new Date(mapDate);

    if (day === 'previous') {
      currentDate.setDate(currentDate.getDate() - 1);
    } else {
      currentDate.setDate(currentDate.getDate() + 1);
    }
    const updatedDate = currentDate.toISOString().split('T')[0];

    setMapDate(updatedDate);
    setSearchQuery(prevState => ({
      ...prevState,
      startDate: updatedDate,
      endDate: updatedDate,
    }));
  };

  // **** Project Details Form Validation Schema ****
  const ProjectDetailsFormikSchema = Yup.object().shape({
    date_scheduled_start: Yup.string()
      .trim()
      .nullable()
      .when('date_scheduled', date_scheduled => {
        if (date_scheduled) {
          return Yup.string().trim().required('Required');
        }
      }),
    date_scheduled_end: Yup.string()
      .trim()
      .nullable()
      .when('date_scheduled', date_scheduled => {
        if (date_scheduled) {
          return Yup.string().trim().required('Required');
        }
      }),
  });
  // **** Project Details Formik Form Values ****
  const projectDetailsFormik = useFormik({
    initialValues: {
      store_number: projectData?.store?.store_number,
      type: projectData?.project_type?.project_type,
      category: projectData?.project_category?.category,
      status: projectData?.status?.status,
      project_number: projectData?.project_number,
      date_sold: projectData?.date_sold,
      client: projectData?.customer
        ? `${projectData?.customer?.first_name} ${projectData?.customer?.last_name}`
        : '',
      address: projectData?.installation_address?.address1,
      mobile_number: projectData?.customer?.customer_phones[0]?.phone_number,
      alternate_number: projectData?.customer?.alternate_phone,
      email: projectData?.customer?.customer_emails[0]?.email,
      ims_status: projectData?.source_status?.status,
      reschedule_reason: projectData?.reschedule_reason,
      rts_follow_up_date: projectData?.rts_follow_up_date,
      date_scheduled_start: projectData?.date_scheduled_start,
      date_scheduled_end: projectData?.date_scheduled_end,
      project_type_id: projectData?.project_type_id,
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      updateProjectScheduleInfo(
        projectData.project_id,
        {
          reschedule_reason_id:
            projectDetailsFormik?.values?.reschedule_reason?.reason_id,
          rts_follow_up_date: projectDetailsFormik?.values?.rts_follow_up_date,
          date_scheduled_start:
            projectDetailsFormik?.values?.date_scheduled_start,
          date_scheduled_end: projectDetailsFormik?.values?.date_scheduled_end,
          project_type_id: projectDetailsFormik?.values?.project_type_id,
        },
        setProjectDataLoading,
        setAlert,
        setReloadList,
        setIsOpen,
        resetForm
      );
    },
    validationSchema: ProjectDetailsFormikSchema,
    enableReinitialize: true,
  });

  // *** View/Edit Project Details on Marker Click ***
  const viewEditProjectDetails = (
    reschedule_reason,
    source_system_id,
    project_id
  ) => {
    setMasterScheduler();
    setOpenScheduleModel(true);
    projectDetailsFormik?.handleReset();

    getRescheduleReasonOptions(
      PROJECT_REPORT.PROJECT,
      source_system_id,
      setRescheduleReasonOptions,
      value => {}
    );

    getProjectDataById(
      project_id,
      setProjectDataLoading,
      setProjectData,
      setIsOpen,
      setOpenScheduleModel
    );
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: true,
      button2Text: 'Save',
      title: 'Edit Project Info',
    }));
  };

  const installerCalendarFilterPopUp = () => {
    setShowTechnicianFilter(true);
  };

  const handleUserTypeSearch = async event => {
    try {
      const searchString = event;
      if (searchString?.length > 2) {
        setUserTypeLoader(true);
        const searchUserTypes = await getUserTypesList({
          searchString,
          setUserTypeLoader,
        });
        if (searchUserTypes) {
          setUserTypeLoader(false);
          setUserTypesOptions(searchUserTypes);
        }
      } else {
        setUserTypesOptions(userTypes);
      }
    } catch (error) {
      setUserTypeLoader(false);
      console.log(error);
    }
  };
  const fetchStore = async () => {
    if (searchStoreText?.length > 2) {
      const response = await getSearchStoreList({
        searchValue: searchStoreText,
        setStoreLoader,
      });
      if (response && response?.length) {
        setStoreOptions(response);
      }
    } else {
      setStoreOptions(projectStores);
    }
  };

  useEffect(() => {
    fetchStore();
  }, [searchStoreText]);

  const fetchWorkroom = async () => {
    if (searchWorkroomText?.length > 2) {
      const dataList = await getSearchProjectWorkRoomList({
        searchValue: searchWorkroomText,
        setWorkRoomLoader,
      });
      setWorkRoomOptions(dataList);
    } else {
      setWorkRoomOptions(workrooms);
    }
  };
  useEffect(() => {
    fetchWorkroom();
  }, [searchWorkroomText]);

  const handleClosePopup = () => {
    setShowTechnicianFilter(false);
  };

  const installerFormik = useFormik({
    initialValues: {
      filterTechnicial: [],
      filterProjectType: '',
      filterStore: '',
      filterCategory: '',
      filterDistrict: '',
      filterUserType: '',
      filterWorkroom: '',
      searchAndAllSelected: false,
    },
    onSubmit: values => {
      const user_id =
        values?.filterTechnicial?.length > 0
          ? values?.filterTechnicial?.map(val => val?.user_id).join(',')
          : null;

      if (user_id?.length > 0) {
        const data = {};
        if (!selectAllTech) {
          data.installerIds = user_id;
        }
        setIsFiltersSelected(true);
        setFilterCalenderData(data);
        setShowTechnicianFilter(false);
      } else if (
        (Array.isArray(values?.filterCategory) &&
          values?.filterCategory?.length > 0) ||
        (Array.isArray(values?.filterStore) &&
          values?.filterStore?.length > 0) ||
        (Array.isArray(values?.filterProjectType) &&
          values?.filterProjectType?.length > 0) ||
        (Array.isArray(values?.filterDistrict) &&
          values?.filterDistrict?.length > 0) ||
        (Array.isArray(values?.filterUserType) &&
          values?.filterUserType?.length > 0) ||
        (Array.isArray(values?.filterWorkroom) &&
          values?.filterWorkroom?.length > 0)
      ) {
        const projectTypeId =
          values?.filterProjectType?.length > 0
            ? values?.filterProjectType
                ?.map(val => val?.project_type_id)
                .join(',')
            : '';

        const projectCategoryId =
          values?.filterCategory && values?.filterCategory?.length > 0
            ? values?.filterCategory
                ?.map(val => val?.project_category_id)
                ?.join(',')
            : '';

        const districts =
          values?.filterDistrict?.length > 0
            ? values?.filterDistrict?.filter(item => !!item)?.join(',')
            : '';

        const storeId =
          values?.filterStore?.length > 0
            ? values?.filterStore?.map(val => val?.store_id)?.join(',')
            : '';

        const userTypeId =
          values?.filterUserType.length > 0
            ? values?.filterUserType
                .filter(item => !!item)
                ?.map(item => item.user_type_id)
                ?.join(',')
            : [];

        const selectedWorkroomView =
          values?.filterWorkroom?.length > 0
            ? values?.filterWorkroom
                .filter(workRoom => workRoom?.type_id)
                .map(workRoom => workRoom?.type_id)
                .join(',')
            : '';

        const data = {
          projectCategoryId: projectCategoryId,
          projectStoreId: storeId,
          projectTypeIds: projectTypeId,
          workRoomIds: selectedWorkroomView,
          districts: districts,
          userTypeIds: userTypeId,
        };
        setFilterCalenderData(data);
        setShowTechnicianFilter(false);
        setIsFiltersSelected(true);
      }
    },
  });

  const handleResetFilters = async () => {
    !!childGetParms &&
      (await getCalendarData(
        childGetParms,
        setCalendarData,
        setCalendarLoading,
        setAlert,
        setFilterCalenderData
      ));
    setFilterCalenderData('');
    installerFormik.handleReset();
  };

  const handleStoresChange = event => {
    setCheckedStores(event.target.checked);
    if (!event.target.checked) {
      const filteredData = mapData?.filter(item => !item.is_store);
      setMapData(filteredData);
    }
  };
  const handleAssignedChange = event => {
    setSearchQuery(prevState => ({
      ...prevState,
      startDate: filterDates?.start_date ? filterDates.start_date : currDate,
      endDate: filterDates?.end_date ? filterDates.end_date : currDate,
      assigned: event.target.checked ? 1 : 0,
      unAssigned: searchQuery?.unAssigned,
    }));
    setCheckedAssigned(event.target.checked);
  };
  const handleUnAssignedChange = event => {
    setSearchQuery(prevState => ({
      ...prevState,
      startDate: filterDates?.start_date ? filterDates.start_date : currDate,
      endDate: filterDates?.end_date ? filterDates.end_date : currDate,
      assigned: searchQuery?.assigned,
      unAssigned: event.target.checked ? 1 : 0,
    }));
    setCheckedUnAssigned(event.target.checked);
  };

  return (
    <>
      {openScheduleModel ? <BlockLoader /> : null}
      <div className="grid">
        <div className="col-12">{alert.exists && <Alert />}</div>
      </div>
      <div className="grid w-full grid-nogutter">
        <div className="col-12 md:col-7 flex align-items-center py-0">
          <h2 className="my-0">{isScheduler ? 'Scheduler' : 'Calendar'}</h2>
        </div>
        {isScheduler ? (
          <div className="col-12 md:col-5 py-0">
            {schedulerLoader ? (
              <div className="flex justify-content-end w-12">
                <Skeleton className="mb-2 h-3rem w-12"></Skeleton>
              </div>
            ) : (
              <div className="flex align-items-center">
                <CustomFilter
                  defaultFilters={defaultFilters}
                  handleDefaultFilters={handleDefaultFilters}
                  setSelectedFilters={setSelectedFilters}
                  fetchDefaultFilterList={fetchDefaultFilterList}
                  setCalendarFilterData={setCalendarFilterData}
                />
              </div>
            )}
          </div>
        ) : null}
        {!isProjectScheduler ? (
          <div className="col-12 xl:col-12 mb-2">
            {!calendarFullScreen && (
              <AlertInformation view={view} isScheduler={isScheduler} />
            )}
          </div>
        ) : null}
      </div>
      {isScheduler ? (
        <div className="grid w-full grid-nogutter">
          <div className={`col-12 px-0 ${calendarFullScreen ? 'hidden' : ''}`}>
            {schedulerLoader ? (
              <div className="flex justify-cintent-center w-12">
                <TableLoader columnCount={6} noOfRow={9} mTopBottom={0} />
              </div>
            ) : (
              <div className="rts-table">
                <GenericDataTable
                  title={'Ready to Schedule Report'}
                  columns={readyToScheduleTableColumns}
                  data={readyToScheduleDataArray}
                  options={readyToScheduleDataTableOptions}
                />
              </div>
            )}
          </div>
        </div>
      ) : null}
      <>
        {isScheduler && calendarFilterData && !calendarFullScreen ? (
          <ProjectDetails selectedRow={selectedRow} />
        ) : null}

        <SchedulerTabs
          buttonHandler={buttonHandler}
          initialLoader={initialLoader}
          view={view}
          isScheduler={isScheduler}
          calendarFullScreen={calendarFullScreen}
          installerCalendarFilterPopUp={installerCalendarFilterPopUp}
          schedulerComponent={schedulerComponent}
          isFiltersSelected={isFiltersSelected}
        />

        <div
          className={`bg-white w-12 px-2 ${!isScheduler ? 'calendar-page-height' : 'calendar-full-height'}`}
        >
          {view === PROJECT_REPORT.FULL_CALENDAR && (
            <NewScheduler
              reportData={reportData?.items}
              calendarViewAPICall={calendarViewAPICall}
              setCalendarViewAPICall={setCalendarViewAPICall}
              calendarFilterData={calendarFilterData}
              datePeriodForTechnicianView={datePeriodForTechnicianView}
            />
          )}
        </div>
        <div
          className={`grid w-12 grid-nogutter ${calendarFullScreen ? 'calendar-fullScreen' : null} ${!isScheduler ? 'scheduler-page-height' : 'scheduler-full-height'}`}
        >
          {view === PROJECT_REPORT.CALENDAR || view === PROJECT_REPORT.SPLIT ? (
            <div
              className={`col-12 ${
                view === PROJECT_REPORT.SPLIT && !calendarFullScreen
                  ? 'xl:col-6 border-right-1 border-200'
                  : 'xl:col-12 p-0'
              } `}
            >
              {(schedulerComponent && isScheduler) || isFiltersSelected ? (
                <div
                  className={`bg-white ${isScheduler ? 'scheduler-page' : 'calendar-component'}`}
                >
                  <div className="flex flex-column">
                    <div className="col-12 p-0">
                      <CalenderContainer
                        viewEditProjectDetails={viewEditProjectDetails}
                        calendarFilterData={calendarFilterData}
                        setCalendarFilterData={setCalendarFilterData}
                        setReloadList={setReloadList}
                        projectDataLoading={projectDataLoading}
                        calendarFullScreen={calendarFullScreen}
                        setCalendarFullScreen={setCalendarFullScreen}
                        reloadCalender={reloadCalender}
                        setSelected={setSelected}
                        reportData={reportData?.items}
                        statusOptions={statusOptions}
                        filterCalenderData={filterCalenderData}
                        setFilterCalenderData={setFilterCalenderData}
                        setChildGetParms={setChildGetParms}
                        calendarDataResetData={calendarData}
                        schedulerLoader={schedulerLoader}
                        setOpenScheduleModel={setOpenScheduleModel}
                        setInitialLoader={setInitialLoader}
                        initialLoader={initialLoader}
                        resetInstallerFormik={installerFormik.handleReset}
                        view={view}
                        isScheduler={isScheduler}
                        datePeriodForCalenderView={datePeriodForCalenderView}
                        setDatePeriodForCalenderView={
                          setDatePeriodForCalenderView
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            ''
          )}
          {(view === PROJECT_REPORT.MAP || view === PROJECT_REPORT.SPLIT) &&
            !calendarFullScreen && (
              <div
                className={`col-12 xl:col-${
                  view === PROJECT_REPORT.SPLIT ? 6 : 12
                }`}
              >
                <div
                  className="bg-white"
                  classes={{ root: classes.mapPaper }}
                  elevation={0}
                >
                  <div className="flex flex-column">
                    <div className="col-12 mb-4">
                      <MapContainer
                        lat={59.95}
                        long={30.33}
                        markers={mapData}
                        viewEditProjectDetails={viewEditProjectDetails}
                        categoryOptions={categoryOptions}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        mapDate={mapDate}
                        setMapDate={setMapDate}
                        changeMapDate={changeMapDate}
                        selected={selected}
                        setSelected={setSelected}
                        showErrorMsg={showErrorMsg}
                        handleStoresChange={handleStoresChange}
                        handleAssignedChange={handleAssignedChange}
                        handleUnAssignedChange={handleUnAssignedChange}
                        checkedAssigned={checkedAssigned}
                        checkedUnAssigned={checkedUnAssigned}
                        checkedStores={checkedStores}
                        setFilterDates={setFilterDates}
                        currDate={currDate}
                        handleCategorySearch={handleCategorySearch}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
        <GenericDialog
          fullwidth
          isOpen={isOpen}
          dialogWidth="lg"
          handleClose={() => {
            setIsOpen(false);
            projectDetailsFormik?.handleReset();
          }}
          handleSave={projectDetailsFormik.handleSubmit}
          dialogSettings={dialogSettings}
          disabledButton2={
            !projectDetailsFormik?.dirty || !projectDetailsFormik?.isValid
          }
          disabledButton1={false}
        >
          <div className='col-12 className="flex flex-column gap-2'>
            <div className="grid">
              <div className="col-12 p-0">{alert.exists && <Alert />}</div>
              <div className="col-12 text-right">
                <Link
                  to={`/project/view/${projectData?.project_id}`}
                  target="_blank"
                  className="border-1 px-3 py-2 border-round-md no-underline border-primary text-primary"
                >
                  View Project
                </Link>
              </div>
              <div className="col-12">
                <form onSubmit={projectDetailsFormik.handleSubmit}>
                  <ProjectDetailsViewEditForm
                    projectDetailsFormik={projectDetailsFormik}
                    rescheduleReasonOptions={rescheduleReasonOptions}
                  />
                </form>
              </div>
              <div className="col-12">
                {isOpen && (
                  <Installer
                    formik={{
                      values: {
                        project_id: projectData.project_id,
                        date_scheduled:
                          projectDetailsFormik?.values?.date_scheduled,
                        proj_end_date:
                          projectDetailsFormik?.values?.project_end_date,
                      },
                    }}
                    setReloadCalender={setReloadCalender}
                    reloadCalender={reloadCalender}
                    projectId={projectData.project_id}
                    schedulerEditPermission={
                      !checkPermission(
                        permissions?.viewEditProject?.actionEditScheduler
                      )
                    }
                    installerOptions={installerOptions}
                  />
                )}
              </div>
              <div className="col-12">
                <Notes
                  formik={{ values: { project_id: projectData.project_id } }}
                  action=""
                  schedulerEditPermission={
                    !checkPermission(
                      permissions?.viewEditProject?.actionEditScheduler
                    )
                  }
                />
              </div>
            </div>
          </div>
        </GenericDialog>
        {showTechnicianFilter ? (
          <TechnicianFilter
            setShowTechnicianFilter={setShowTechnicianFilter}
            handleClosePopup={handleClosePopup}
            showTechnicianFilter={showTechnicianFilter}
            installerFormik={installerFormik}
            handleResetFilters={handleResetFilters}
            installerOptions={installerOptions}
            projectTypeOptions={projectTypeOptions}
            storeOptions={storeOptions}
            categoryOptions={categoryOptions}
            districtOptions={districtOptions}
            userTypesOptions={userTypesOptions}
            workRoomOptions={workRoomOptions}
            setSearchWorkroomText={setSearchWorkroomText}
            setSearchStoreText={setSearchStoreText}
            handleUserTypeSearch={handleUserTypeSearch}
            storeLoader={storeLoader}
            workRoomLoader={workRoomLoader}
            userTypeLoader={userTypeLoader}
            calendarLoading={calendarLoading}
            handleTechnicianSearch={handleTechnicianSearch}
            handleCategorySearch={handleCategorySearch}
            setSelectAllTech={setSelectAllTech}
            selectAllTech={selectAllTech}
          />
        ) : null}
      </>
    </>
  );
};

export default ProjectReport;
