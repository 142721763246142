import React, { useEffect, useState, useRef, useContext } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

import PFButton from '../../../shared/PFPrime/PFButton';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';

import { ShippingContext } from '../ShippingContainer';

import {
  packageReceivedGetService,
  packageReceivedPostService,
  packageReceivedPatchService,
} from '../services/shipping.service';

export const ShippingHeader = () => {
  const toast = useRef(null);
  const previousValuesRef = useRef({});
  const { shippingContextValue, setShippingContextValue } =
    useContext(ShippingContext);

  const [receivedPackage, setReceivedPackage] = useState(0);

  const projectShippingGetApi = async () => {
    const res = await packageReceivedGetService(
      shippingContextValue?.project_id
    );
    if (res?.status) {
      res?.data?.expected_package &&
        setFieldValue('expected_package', res?.data?.expected_package || '');
      res?.data?.project_shipping_id &&
        setShippingContextValue(preValue => ({
          ...preValue,
          project_shipping_id: res?.data?.project_shipping_id || null,
          shippingData: res?.data,
        }));

      setReceivedPackage(res?.data?.received_package || '0');
      previousValuesRef.current = {
        expected_package: res?.data?.expected_package || null,
      };
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Internal server error',
      });
    }
  };

  useEffect(() => {
    if (shippingContextValue?.deleteFlag) {
      projectShippingGetApi().then(() => {
        setShippingContextValue(preValue => ({
          ...preValue,
          deleteFlag: false,
        }));
      });
    }
  }, [shippingContextValue?.deleteFlag]);

  const projectShippingPostApi = async values => {
    const res = await packageReceivedPostService(
      shippingContextValue?.project_id,
      values
    );
    if (res?.status) {
      res?.data?.expected_package &&
        setFieldValue('expected_package', res?.data?.expected_package || '');

      previousValuesRef.current = {
        expected_package: res?.data?.expected_package || null,
      };
      res?.data?.received_package &&
        setReceivedPackage(res?.data?.received_package);
      projectShippingGetApi();
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Add package successfully',
      });
    }
  };

  const projectShippingPatchApi = async values => {
    const res = await packageReceivedPatchService(
      shippingContextValue?.project_id,
      shippingContextValue?.project_shipping_id,
      values
    );

    if (res?.status) {
      res?.data?.expected_package &&
        setFieldValue('expected_package', res?.data?.expected_package || '');

      previousValuesRef.current = {
        expected_package: res?.data?.expected_package || null,
      };
      res?.data?.received_package &&
        setReceivedPackage(res?.data?.received_package);
      projectShippingGetApi();
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Updated package successfully',
      });
    }
  };

  const { handleSubmit, values, errors, touched, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      expected_package: '',
    },
    validationSchema: Yup.object().shape({
      expected_package: Yup.number()
        .min(
          receivedPackage || 0,
          `Please enter a value of at least ${receivedPackage || 0}`
        )
        .required('Required'),
    }),
    onSubmit: async values => {
      if (!shippingContextValue?.project_shipping_id) {
        projectShippingPostApi(values);
      } else {
        projectShippingPatchApi(values);
      }
    },
  });

  const handleDisabled = () => {
    let res = false;
    const lastValue = values;
    const currentValue = previousValuesRef.current;
    if (JSON.stringify(lastValue) === JSON.stringify(currentValue)) {
      res = true;
    }
    if (!lastValue?.expected_package) {
      res = true;
    }
    return res;
  };

  useEffect(() => {
    shippingContextValue?.project_id && projectShippingGetApi();
  }, [shippingContextValue?.project_id]);

  return (
    <>
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-6">
          <h3 className="text-lg">Shipping</h3>
        </div>
        <div className="col-12 md:col-12 lg:col-6">
          <PFButton
            label="Add New Shipment"
            icon="pi pi-plus"
            iconPos="left"
            onClick={() => {
              shippingContextValue?.setVisibleMode({
                visible: 'add',
                data: {},
              });
              shippingContextValue?.hide();
            }}
            style={{ float: 'right' }}
            disabled={
              shippingContextValue?.project_shipping_id &&
              values?.expected_package &&
              values?.expected_package !== receivedPackage
                ? false
                : true
            }
          />
        </div>
      </div>
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-3 mr-2">
          <span className="p-float-label flex-1 flex align-items-center">
            <PFInputNumber
              icon="pi pi-plus"
              pt={{
                input: {
                  root: {
                    className: 'border-round-left-md border-noround height-40',
                  },
                },
              }}
              name="expected_package"
              value={values?.expected_package || ''}
              onChange={(name, value) => setFieldValue(name, value || '')}
            />
            <PFButton
              icon="pi pi-check"
              iconPos="left"
              className="p-button-primary height-40 border-round-right-md border-noround"
              onClick={handleSubmit}
              disabled={handleDisabled()}
            />
            <label htmlFor="totalPackagesReceived">
              Total # of Packages Expected
            </label>
          </span>
          <PFFormikError
            touched={touched}
            errors={errors}
            field="expected_package"
          />
        </div>
        <div className="col-12 md:col-6 lg:col-3">
          <span className="p-float-label">
            <PFInputText disabled={true} value={receivedPackage} />
            <label htmlFor="received_package">
              Total # of Packages Received
            </label>
          </span>
        </div>
      </div>
    </>
  );
};
