export const REACT_APP_LOGIN_AUTH = process.env.REACT_APP_LOGIN_AUTH;
export const REACT_APP_AUTH_CLIENT_SECRET =
  process.env.REACT_APP_AUTH_CLIENT_SECRET;
export const REACT_APP_AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
export const REACT_APP_AUTH_REFRESH_GRANT_TYPE =
  process.env.REACT_APP_AUTH_REFRESH_GRANT_TYPE;
export const REACT_APP_YOUTUBE_URL = process.env.REACT_APP_YOUTUBE_URL;
export const REACT_APP_DW_BETA = process.env.REACT_APP_DW_BETA;
export const PUBLIC_URL = process.env.PUBLIC_URL;
export const REACT_APP_DOCUMENTS_MS_API =
  process.env.REACT_APP_DOCUMENTS_MS_API;
export const REACT_APP_GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
export const REACT_APP_YOUTUBE_CHANNEL_ID =
  process.env.REACT_APP_YOUTUBE_CHANNEL_ID;
export const REACT_APP_YOUTUBE_KEY = process.env.REACT_APP_YOUTUBE_KEY;
export const REACT_APP_YOUTUBE_MAX_RESULTS =
  process.env.REACT_APP_YOUTUBE_MAX_RESULTS;
export const REACT_APP_GMAPS_KEY = process.env.REACT_APP_GMAPS_KEY;
export const REACT_APP_PSPDFKIT_LICENCE_KEY =
  process.env.REACT_APP_PSPDFKIT_LICENCE_KEY;
export const REACT_APP_PF_ADMIN_ID = process.env.REACT_APP_PF_ADMIN_ID;
export const REACT_APP_CUBE_BASEURL = process.env.REACT_APP_CUBE_BASEURL;
export const REACT_APP_SUPER_CLIENTS = process.env.REACT_APP_SUPER_CLIENTS;
export const REACT_APP_ALLOWED_CLIENTS_FOR_WIDGET_CREATION =
  process.env.REACT_APP_ALLOWED_CLIENTS_FOR_WIDGET_CREATION;
export const REACT_APP_VIEW_DASHBOARD_PERFORMANCE_USERS =
  process.env.REACT_APP_VIEW_DASHBOARD_PERFORMANCE_USERS;
export const REACT_APP_ENABLE_CUSTOMER_LIST =
  process.env.REACT_APP_ENABLE_CUSTOMER_LIST;
export const REACT_APP_AUDIT_TAB_ENABLED =
  process.env.REACT_APP_AUDIT_TAB_ENABLED;
export const REACT_PF_CSV_UPLOAD_SIZE = process.env.REACT_PF_CSV_UPLOAD_SIZE;
export const REACT_APP_CONFIGURATION_AUDIT_LOG_ENABLED =
  process.env.REACT_APP_CONFIGURATION_AUDIT_LOG_ENABLED;

export const REACT_APP_AUTH_AUTHORIZE_GRANT_TYPE =
  process.env.REACT_APP_AUTH_AUTHORIZE_GRANT_TYPE;

export const REACT_APP_RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
export const REACT_APP_CRYPTO_SECRET_KEY =
  process.env.REACT_APP_CRYPTO_SECRET_KEY;
