import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
const initialState = {
  isLoading: true,
  installersList: [],
  filterInstallerList: [],
  selectedInstallers: [],
  eventsList: [],
  eventsQueryParams: {
    date: moment(new Date()).format(),
    period: 'W',
  },
  isProjectEditDialogOpen: false,
  projectData: {},
  rescheduleReasons: [],
  projectEditDialogSettings: {
    title: 'Edit Project Info',
    button1Text: '',
    button2Text: 'Save',
    showButton1: true,
    showButton2: true,
  },
  refreshFlag: false,
  viewChangeFlag: false,
  isSelectDialogOpen: false,
  newEventInfo: {},
  dropInfo: {},
  dropDialogData: {},
  isDropDialogOpen: false,
  isWeekendsVisible: false,
  isSelectAllInstallers: false,
};

const newSchedulerSlice = createSlice({
  name: 'newScheduler',
  initialState: initialState,
  reducers: {
    setNewSchedulerComponent(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      state.installersList = payload.hasOwnProperty('installersList')
        ? payload.installersList
        : state.installersList;
      state.filterInstallerList = payload.hasOwnProperty('filterInstallerList')
        ? payload.filterInstallerList
        : state.filterInstallerList;
      state.selectedInstallers = payload.hasOwnProperty('selectedInstallers')
        ? payload.selectedInstallers
        : state.selectedInstallers;
      state.eventsList = payload.hasOwnProperty('eventsList')
        ? payload.eventsList
        : state.eventsList;
      state.eventsQueryParams = payload.hasOwnProperty('eventsQueryParams')
        ? payload.eventsQueryParams
        : state.eventsQueryParams;
      state.isProjectEditDialogOpen = payload.hasOwnProperty(
        'isProjectEditDialogOpen'
      )
        ? payload.isProjectEditDialogOpen
        : state.isProjectEditDialogOpen;
      state.projectData = payload.hasOwnProperty('projectData')
        ? payload.projectData
        : state.projectData;
      state.rescheduleReasons = payload.hasOwnProperty('rescheduleReasons')
        ? payload.rescheduleReasons
        : state.rescheduleReasons;
      state.refreshFlag = payload.hasOwnProperty('refreshFlag')
        ? payload.refreshFlag
        : state.refreshFlag;
      state.viewChangeFlag = payload.hasOwnProperty('viewChangeFlag')
        ? payload.viewChangeFlag
        : state.viewChangeFlag;
      state.isSelectDialogOpen = payload.hasOwnProperty('isSelectDialogOpen')
        ? payload.isSelectDialogOpen
        : state.isSelectDialogOpen;
      state.newEventInfo = payload.hasOwnProperty('newEventInfo')
        ? payload.newEventInfo
        : state.newEventInfo;
      state.dropInfo = payload.hasOwnProperty('dropInfo')
        ? payload.dropInfo
        : state.dropInfo;
      state.dropDialogData = payload.hasOwnProperty('dropDialogData')
        ? payload.dropDialogData
        : state.dropDialogData;
      state.isDropDialogOpen = payload.hasOwnProperty('isDropDialogOpen')
        ? payload.isDropDialogOpen
        : state.isDropDialogOpen;
      state.isWeekendsVisible = payload.hasOwnProperty('isWeekendsVisible')
        ? payload.isWeekendsVisible
        : state.isWeekendsVisible;
      state.isSelectAllInstallers = payload.hasOwnProperty(
        'isSelectAllInstallers'
      )
        ? payload.isSelectAllInstallers
        : state.isSelectAllInstallers;
    },
  },
});

export const { setNewSchedulerComponent } = newSchedulerSlice.actions;

export const newSchedulerReducer = newSchedulerSlice.reducer;
