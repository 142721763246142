export const convertString = value =>
  value !== undefined && value !== null ? value.toString() : null;

export const getPayloadKeys = reqObject => {
  if (reqObject?.receipt_type == 1) {
    const {
      receipt_type,
      box_count,
      receipt_date,
      receipt_note,
      needed,
      from_warehouse_id,
      to_warehouse_id,
    } = reqObject;
    return {
      receipt_type: receipt_type || '',
      box_count: box_count || '',
      receipt_date: receipt_date || null,
      receipt_note: receipt_note || '',
      needed: convertString(needed),
      from_warehouse_id: from_warehouse_id || '',
      to_warehouse_id: to_warehouse_id || '',
    };
  }
  if (reqObject?.receipt_type == 2) {
    const {
      receipt_type,
      needed,
      box_count,
      receipt_date,
      receipt_note,
      technician_id,
      is_received_by_technician,
    } = reqObject;
    return {
      receipt_type: receipt_type || '',
      needed: convertString(needed),
      box_count: box_count || '',
      receipt_date: receipt_date || null,
      receipt_note: receipt_note || '',
      technician_id: technician_id || '',
      is_received_by_technician: convertString(is_received_by_technician),
    };
  }
  if (reqObject?.receipt_type == 3) {
    const {
      receipt_type,
      box_count,
      receipt_date,
      receipt_note,
      technician_id,
      return_warehouse_id,
      bin_location_id,
      is_still_in_warehouse,
    } = reqObject;
    return {
      receipt_type: receipt_type || '',
      box_count: box_count || '',
      receipt_date: receipt_date || null,
      receipt_note: receipt_note || '',
      technician_id: technician_id || '',
      return_warehouse_id: return_warehouse_id || '',
      bin_location_id: bin_location_id || '',
      is_still_in_warehouse: convertString(is_still_in_warehouse),
    };
  }
};
